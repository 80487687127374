import React from "react";
import styles from "./AboutUs.module.css";

const AboutUs = () => {
  return (
    <>
      <div className={styles.aboutUsContainer}>
        <div className={styles.whoWeAreContainer}>
          <h1 className={styles.whoWeAreHeader}>The next-level casino games provider, erasing the boundaries between gaming and gambling</h1>
          <h2 className={styles.whoWeAreDescription}>
            Founded in Georgia in 2015 by industry experts, SmartSoft set out to create a one-of-a-kind casino games provider that offers high-quality gaming experiences worldwide. 
            By combining interactive features with captivating visuals, we blend gaming and gambling to deliver more engaging, entertaining, and superior experiences. 
            Our mission is not just to attract players but to enhance their enjoyment.
            <span>
            Launched in 2018, JetX has revolutionized the non-traditional casino game category. With multiplayer functionality, chat features, and regular network promotions, 
            it offers an unmatched experience for players worldwide. JetX was a breakthrough in its category and showcases SmartSoft’s 
            dedication to innovation and unforgettable gaming experiences.
            </span>
            <span>
              {" "}
              SmartSoft is recognized for visually stunning, interactive games that set industry standards. With a diverse portfolio and partnerships in over 70 countries, 
              we have a strong global presence. Enter the world of X Games and explore the ultimate gaming adventure with our range of non-traditional games. 
              Featuring eye-catching designs, impeccable functionality, and gamification elements, our games are crafted to deliver both visually appealing and highly engaging experiences for players.
            </span>
            {/* <span>
              As we expand, our aim remains to increase profitability of our
              partners and delivering exceptional service, through consistent
              improvement of our technological performance and growth of our
              portfolio.
            </span> */}
          </h2>
        </div>
        <img
          className={styles.aboutUsImage}
          src="/assets/images/AboutUs.png"
          alt="SmartSoft"
        />
      </div>
      {/* <div className={styles.features}>
                <div className={styles.featureItem}>
                    <img src="/assets/images/features/ApiIntegration.png" alt="API Integration"/>
                    <div className={styles.featureTitle}> Simple Integration:</div>
                    <div className={styles.featureDescription}> Seamless wallet API integration</div>
                </div>
                <div className={styles.featureItem}>
                    <img src="/assets/images/features/DeviceCompability.png" alt="Device Compability"/>
                    <div className={styles.featureTitle}> Device Compability:</div>
                    <div className={styles.featureDescription}> All operating systems on mobile and desktop</div>
                </div>
                <div className={styles.featureItem}>
                    <img src="/assets/images/features/Languages.png" alt="Languages"/>
                    <div className={styles.featureTitle}> Languages:</div>
                    <div className={styles.featureDescription}>MultiLanguagal interface customizable on demand</div>
                </div>
                <div className={styles.featureItem}>
                    <img src="/assets/images/features/Currencies.png" alt="Currencies"/>
                    <div className={styles.featureTitle}> Currencies:</div>
                    <div className={styles.featureDescription}>All international and crypto currencies</div>
                </div>
                <div className={styles.featureItem}>
                    <img src="/assets/images/features/Promotions.png" alt="Promotions"/>
                    <div className={styles.featureTitle}> Promotional tools:</div>
                    <div className={styles.featureDescription}>Variety of player systems with network promotions,
                        jackpots and tailored campaign modules
                    </div>
                </div>
                <div className={styles.featureItem}>
                    <img src="/assets/images/features/Controls.png" alt="Controls"/>
                    <div className={styles.featureTitle}> Contorls:</div>
                    <div className={styles.featureDescription}>User-friendly back office, admin panel and statistics
                    </div>
                </div>
                <div className={styles.featureItem}>
                    <img src="/assets/images/features/Support.png" alt="Support"/>
                    <div className={styles.featureTitle}> Support:</div>
                    <div className={styles.featureDescription}>24/7 available online for free</div>
                </div>
                <div className={styles.featureItem}>
                    <img src="/assets/images/features/Portfolio.png" alt="Portfolio"/>
                    <div className={styles.featureTitle}> Portfolio:</div>
                    <div className={styles.featureDescription}>Over 50 games and at least two new releases every month
                    </div>
                </div>
                <div className={styles.featureItem}>
                    <img src="/assets/images/features/Partners.png" alt="Partners"/>
                    <div className={styles.featureTitle}> Partners:</div>
                    <div className={styles.featureDescription}>Games already integrated on 500+ online casinos brands
                        all around the globe
                    </div>
                </div>
                <div className={styles.featureItem}>
                    <img src="/assets/images/features/Compliance.png" alt="Partners"/>
                    <div className={styles.featureTitle}> Compliance:</div>
                    <div className={styles.featureDescription}>Games are certified and licensed for all major jurisdictions
                    </div>
                </div>


            </div>
            <div className={styles.mileStonesContainer}>
                <div className={styles.mileStonesWrapper1}>
                    <div className={styles.historyItem}>
                        <div className={styles.historyHead}>
                            COMPANY FOUNDATION
                        </div>
                        <div className={styles.historyDescription}>
                            Three experienced software developers founded the company on 17 April 2015 in Tbilisi, Georgia.
                        </div>
                    </div>

                    <div className={styles.historyItem}>
                        <div className={styles.historyHead}>
                            DIVERSIFYING PORTFOLIO
                        </div>
                        <div className={styles.historyDescription}>
                            SmartSoft Gaming introduces new slots, casinos and board games and gains the leading position in the local market.
                        </div>
                    </div>

                    <div className={styles.historyItem}>
                        <div className={styles.historyHead}>
                            FLAGSHIP GAME
                        </div>
                        <div className={styles.historyDescription}>
                            The year when JetX was released. It was a gamechanger for the whole company and, later, for the entire industry.
                        </div>
                    </div>
                    <div className={styles.historyItem}>
                        <div className={styles.historyHead}>
                            NEW HIGHTS AHEAD
                        </div>
                        <div className={styles.historyDescription}>
                            SmartSoft Gaming presented products to the international market during the first exhibition.
                        </div>
                    </div>
                </div>
                <img className={styles.mileStone} src="/assets/images/features/MileStones.png" alt="MileStones"/>
            </div>


            <div className={styles.mileStonesContainer1}>
                <div className={styles.mileStonesWrapper1}>
                    <div className={styles.historyItem}>
                        <div className={styles.historyHead}>
                            BIG STEPS FORWARD
                        </div>
                        <div className={styles.historyDescription}>
                            For the first time, our games ventured outside Georgia. The company has introduced improved player engagement and marketing tools.
                        </div>
                    </div>

                    <div className={styles.historyItem}>
                        <div className={styles.historyHead}>
                            INTERNATIONAL RECOGNITION
                        </div>
                        <div className={styles.historyDescription}>
                            Games were integrated into 20+ international brands. SmartSoft Gaming was celebrated as a debutant of the year title at the Login Casino Awards.
                        </div>
                    </div>

                    <div className={styles.historyItem}>
                        <div className={styles.historyHead}>
                            VERGE OF A BREAKTHROUGH
                        </div>
                        <div className={styles.historyDescription}>
                            2021 was a massive year for us. Not only we have developed new games, but we also attended several conferences and grown our brand awareness. Like that, we acquired admiration among respectable aggregators and made numerous extensive partnerships worldwide.

                        </div>
                    </div>
                    <div className={styles.historyItem}>
                        <div className={styles.historyHead}>
                            THE FUTURE IS BRIGHT.
                        </div>
                        <div className={styles.historyDescription}>
                            As it's been said and recited - the best is yet to come. There is no doubt that new partnerships and projects will lead us to something extraordinary. We create our own way with the help of trustworthy professionals and hardworking people.
                        </div>
                    </div>
                </div>
                <img className={styles.mileStone} src="/assets/images/features/MileStones.png" alt="MileStones"/>
            </div>
            <div className={styles.statistic}>
                <div className={styles.statisticItem}>
                    <div className={styles.statisticHeader}>
                        Number Of games
                    </div>
                    <div className={styles.statisticNumber}>
                        50+
                    </div>
                </div>

                <div className={styles.statisticItem}>
                    <div className={styles.statisticHeader}>
                        Employees
                    </div>
                    <div className={styles.statisticNumber}>
                        100+
                    </div>
                </div>

                <div className={styles.statisticItem}>
                    <div className={styles.statisticHeader}>
                        Live on Websites
                    </div>
                    <div className={styles.statisticNumber}>
                        500+
                    </div>
                </div>
                <div className={styles.statisticItem}>
                    <div className={styles.statisticHeader}>
                        Monthly players
                    </div>
                    <div className={styles.statisticNumber}>
                        1M+
                    </div>
                </div>
            </div> */}
    </>
  );
};

export default AboutUs;
